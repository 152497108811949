import { sampleStatuses } from '../util/ConstUtils'
import { projectResponseToProjects } from '../mappers/projectMapper'
import * as collectionMapper from '../mappers/collectionMapper'
import { ShootTypes } from '../enums/shootTypes'
import { unescape, isEmpty, get, pickBy, isNull, isArray } from 'lodash'
import moment from 'moment'
import {
  DATE_FORMAT,
  convertTableDateFormat,
  getDisplayImage,
  getDisplayImageList,
  getPegasusID,
} from 'util/CommonUtils'
import { buildingNames } from '../util/ConstUtils'
import { getSampleIDPrefix } from '../util/CommonUtils'
import { listInputs } from '../pages/AddFilters/constants'
import { PROPS, SAMPLE, SCENIC } from '../enums/Tabs'
import { getAllUsers } from 'services/inventoryService'

export function propsToPropsRequest(params, type = '') {
  const propsData = params?.props?.map((prop) => propToPropRequest(prop, type))
  return type === 'edit' ? { prop_requests: propsData } : { props: propsData }
}
export function scenicToScenicRequests(params, type = '') {
  const scenicData = params?.scenicAssets?.map((prop) =>
    scenicToScenicRequest(prop, type)
  )
  return type === 'edit'
    ? { scenic_assets: scenicData }
    : { scenicAssets: scenicData }
}
export function scenicToScenicRequest(scenic = {}, type = '') {
  const { createPropBy = '', color_ids = [] } = scenic
  const colorIdList = color_ids?.map((color) => color?.id)
  const itemObj = pickBy({
    brand: scenic?.brand || null,
    brand_id: scenic?.brandId || null,
  })
  const formattedProp = {
    bundle_quantity: scenic?.bundle_quantity,
    category_id: scenic?.category_id,
    color_id: colorIdList ?? [],
    cost: scenic?.scenic_cost,
    description: scenic?.description,
    is_bundle: scenic?.is_bundle,
    item_quantity: scenic?.item_quantity,
    material_id: scenic?.material_id,
    asset_notes: scenic?.asset_notes,
    channel_id: scenic?.channels,
    pegasus_id: getPegasusID(scenic?.pegasusId),
    size: scenic?.scenic_size,
    source_id: scenic?.source_id,
    sub_category_id: scenic?.sub_category_id,
    target_brand: scenic?.target_brand,
    user_id: scenic?.user_id,
    item_details: Object.entries(itemObj).length > 0 ? itemObj : null,
  }
  // let updatedEditFormattedProps = {} //add pegasus id incase of editing an existing prop
  // if (type === 'edit') {
  //   updatedEditFormattedProps = Object.assign({}, formattedProp, {
  //     ...formattedProp,
  //     pegasus_id: getPegasusID(prop?.pegasusId),
  //   })
  // }
  return formattedProp
}

export function propToPropRequest(prop = {}, type = '') {
  const { createPropBy = '', color_id = [] } = prop
  const colorIdList = color_id?.map((color) => color?.id)
  const itemObj = pickBy({
    tcin: prop?.tcin || null,
    dpci: prop?.dpci || null,
    vendor_id: prop?.vendorId || null,
    brand: prop?.brand || null,
    launch_date: null,
    brand_id: prop?.brandId || null,
    is_owned_brand: prop?.isOwnedBrand || null,
    upc: prop?.upc || null,
    manufacturer_style: prop?.manufacturerStyle || null,
    sub_class_id: prop?.subClassId || null,
  })
  const formattedProp = {
    bundle_quantity: prop?.bundle_quantity,
    category_id: prop?.category_id,
    color_id: colorIdList ?? [],
    cost: prop?.prop_cost,
    description: prop?.prop_description,
    is_bundle: prop?.is_bundle,
    item_quantity: prop?.item_quantity,
    material_id: prop?.material_id,
    sample_id:
      createPropBy === 'CONVERT_SAMPLE_TO_PROP'
        ? prop?.pegasusId.substring(2, prop?.pegasusId.length)
        : '',
    size: prop?.prop_size,
    source_id: prop?.source_id,
    studio_notes: prop?.prop_studio_notes,
    sub_category_id: prop?.sub_category_id,
    target_brand: prop?.target_brand,
    user_id: prop?.user_id,
    item_details: Object.entries(itemObj).length > 0 ? itemObj : null,
  }
  let updatedEditFormattedProps = {} //add pegasus id incase of editing an existing prop
  if (type === 'edit') {
    updatedEditFormattedProps = Object.assign({}, formattedProp, {
      ...formattedProp,
      pegasus_id: getPegasusID(prop?.pegasusId),
    })
  }
  return type === 'edit' ? updatedEditFormattedProps : formattedProp
}

export async function samplesResponseToSamples(response = []) {
  let allUsers = []
  const { data = {} } = response
  const { samples = [] } = data
  const sampleType = samples[0]?.type
  if (sampleType === PROPS || sampleType === SCENIC) {
    const response = await getAllUsers()
    allUsers = userNamesResponseToSelect(response)
  }
  return response?.data?.samples.map((sample) =>
    sampleResponseToSample(
      {
        ...sample,
        ...sample.item_data,
        ...sample.sample_data,
      },
      allUsers
    )
  )
}

export function samplesResponseToArchSamples(response = []) {
  return response?.data?.samples?.map((sample) =>
    sampleResponseToArchSample(sample)
  )
}
export function sampleResponseToArchSample(sample) {
  const { pegasus_id = '', type = 'SAMPLE' } = sample
  return {
    pegasusId: getSampleIDPrefix(pegasus_id, type),
    sampleId: sample.pegasus_id,
    orderNum: sample.order_num,
    sample_status: sample.sample_status,
    sampleStatus: sampleStatuses
      .filter((status) => sample.sample_status === status.key)
      .map((status) => status.value)
      .toString(),
    tcin: sample.tcin,
    dpci: sample.dpci,
    userId: sample.user_id,
    vendorId: sample.vendor_id,
    vendorEmail: sample.vendor_email_id,
    shipToLoc: sample.ship_to_loc,
    shipToLocToPrint: sample.ship_to_loc,
    shipToDestination: sample.ship_to_destination,
    dueDate: convertTableDateFormat(sample.sample_due_date, DATE_FORMAT),
    brand: sample.brand || '',
    vendorNotes: sample.vendor_notes || '',
    studioNotes: sample.studio_notes || '',
    shipmentId: sample.shipment_id,
    createDate: convertTableDateFormat(sample.create_ts_cst, DATE_FORMAT),
    sampleLaunchDate: convertTableDateFormat(sample.launch_date, DATE_FORMAT),
    samplePreviousLaunchDate: convertTableDateFormat(
      sample.previous_launch_date,
      DATE_FORMAT
    ),
    location: sampleLocationResponseToLocation(sample.location),
    projects:
      isArray(sample?.projects) && !isEmpty(sample?.projects)
        ? projectResponseToProjects(
            sample.projects.filter((x) => x.shoot_type === ShootTypes.PROJECT)
          )
        : null,
    collections:
      isArray(sample?.projects) && !isEmpty(sample?.projects)
        ? collectionMapper.collectionResponseToCollections(
            sample.projects.filter(
              (x) => x.shoot_type === ShootTypes.COLLECTION
            )
          )
        : null,
    projectStartDate:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? convertTableDateFormat(
            getClosestProject(sample.projects).shoot_start_date,
            DATE_FORMAT
          )
        : null,
    projectEndDate:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? convertTableDateFormat(
            getClosestProject(sample.projects).shoot_end_date,
            DATE_FORMAT
          )
        : null,
    projectName:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? getClosestProject(sample.projects).name
        : null,
    projectCreator:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? getClosestProject(sample.projects).creator_name
        : null,
    receivedDate: sample.received_date
      ? convertTableDateFormat(sample.received_date, DATE_FORMAT)
      : '',
    dispositionNotes:
      sample.disposition_notes !== null ? sample.disposition_notes : '',
    warehouseNotes:
      sample.warehouse_notes !== null ? sample.warehouse_notes : '',
    digital_markdown_date: sample.digital_markdown_date
      ? convertTableDateFormat(sample.digital_markdown_date, DATE_FORMAT)
      : '',
    disposition_override_date: sample.disposition_override_date
      ? convertTableDateFormat(sample.disposition_override_date, DATE_FORMAT)
      : '',
    store_markdown_date: sample.store_markdown_date
      ? convertTableDateFormat(sample.store_markdown_date, DATE_FORMAT)
      : '',
    disposition_date: sample.disposition_date
      ? convertTableDateFormat(sample.disposition_date, DATE_FORMAT)
      : '',
    archivedTs: sample.archived_ts
      ? convertTableDateFormat(sample.archived_ts, DATE_FORMAT)
      : '',
    histories: sample.histories || [],
  }
}

export function sampleResponseToSample(sample, allUsers) {
  const { pegasus_id = '', type = SAMPLE, projects = [] } = sample
  const closestProject = getClosestProject(projects) || {}
  const { creator_name = {} } = closestProject
  let result = {
    pegasusId: getSampleIDPrefix(pegasus_id, type),
    sampleId: sample.pegasus_id,
    orderNum: sample.order_num,
    sample_status: sample.sample_status,
    sampleStatus: sampleStatuses
      .filter((status) => sample.sample_status === status.key)
      .map((status) => status.value)
      .toString(),
    tcin: sample.tcin,
    dpci: sample.dpci,
    userId: sample.user_id,
    vendorId: sample.vendor_id,
    vendorEmail: sample.vendor_email_id,
    shipToLoc: sample.ship_to_loc,
    shipToLocToPrint: sample.ship_to_loc,
    shipToDestination: sample.ship_to_destination,
    dueDate: convertTableDateFormat(sample.sample_due_date, DATE_FORMAT),
    brand: sample.brand || '',
    vendorNotes: sample.vendor_notes || '',
    studioNotes: sample.studio_notes || '',
    shipmentId: sample.shipment_id,
    createDate: convertTableDateFormat(sample.create_ts, DATE_FORMAT),
    propCreatedDate: convertTableDateFormat(sample.create_ts, DATE_FORMAT),
    scenicCreatedDate: convertTableDateFormat(sample.create_ts, DATE_FORMAT),
    sampleLaunchDate: convertTableDateFormat(sample.launch_date, DATE_FORMAT),
    latestComment: sample?.latest_comment || '',
    samplePreviousLaunchDate: convertTableDateFormat(
      sample.previous_launch_date,
      DATE_FORMAT
    ),
    location: sampleLocationResponseToLocation(sample.location),
    projects:
      isArray(sample?.projects) && !isEmpty(sample?.projects)
        ? projectResponseToProjects(
            sample?.projects.filter((x) => x.shoot_type === ShootTypes.PROJECT)
          )
        : null,
    collections:
      isArray(sample?.projects) && !isEmpty(sample?.projects)
        ? collectionMapper.collectionResponseToCollections(
            sample.projects.filter(
              (x) => x.shoot_type === ShootTypes.COLLECTION
            )
          )
        : null,
    projectStartDate:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? convertTableDateFormat(
            getClosestProject(sample.projects).shoot_start_date,
            DATE_FORMAT
          )
        : null,
    projectEndDate:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? convertTableDateFormat(
            getClosestProject(sample.projects).shoot_end_date,
            DATE_FORMAT
          )
        : null,
    projectName:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? getClosestProject(sample.projects).name
        : null,
    projectCreator:
      isArray(sample?.projects) &&
      !isEmpty(sample?.projects) &&
      getClosestProject(sample.projects) !== null
        ? creator_name
        : null,
    receivedDate: sample.received_date
      ? convertTableDateFormat(sample.received_date, DATE_FORMAT)
      : '',
    dispositionNotes:
      sample.disposition_notes !== null ? sample.disposition_notes : '',
    warehouseNotes:
      sample.warehouse_notes !== null ? sample.warehouse_notes : '',
    digital_markdown_date: sample.digital_markdown_date
      ? convertTableDateFormat(sample.digital_markdown_date, DATE_FORMAT)
      : '',
    disposition_override_date: sample.disposition_override_date
      ? convertTableDateFormat(sample.disposition_override_date, DATE_FORMAT)
      : '',
    store_markdown_date: sample.store_markdown_date
      ? convertTableDateFormat(sample.store_markdown_date, DATE_FORMAT)
      : '',
    disposition_date: sample.disposition_date
      ? convertTableDateFormat(sample.disposition_date, DATE_FORMAT)
      : '',
    itemType: sample?.type || 'SAMPLE',
    sampleImageUrl: sample?.image_urls || [],
  }

  if (sample.type === PROPS) {
    const propsData = propsResponseToSample(sample, allUsers)
    result = { ...result, ...propsData }
  }
  if (sample.type === SCENIC) {
    const scenicData = scenicResponseToSample(sample, allUsers)
    result = { ...result, ...scenicData }
  }
  return result
}

export const updateMappedImage = (data) => {
  const { sampleImageUrl = [], imageUrl = '' } = data
  const tcinImageUrl = imageUrl
    ? [{ image_url: imageUrl, isNotDeleted: true }]
    : []
  const imageListForSamples = Array.from(
    new Set([...tcinImageUrl, ...sampleImageUrl])
  )
  const sampleImagePopupUrl =
    data?.type === 'SAMPLE' || isEmpty(data?.type)
      ? imageListForSamples
      : data?.sampleImageUrl
  const updatedDataWithImage = {
    ...data,
    imagePopupUrl: data?.imageUrl,
    imageUrl: getDisplayImage(data?.imageUrl),
    sampleImageUrl: getDisplayImage(sampleImagePopupUrl),
    sampleImageDPURL:
      getDisplayImageList(sampleImagePopupUrl) ||
      getDisplayImage(data?.imageUrl),
    sampleImagePopupUrl: sampleImagePopupUrl,
    sampleImageDPURLView:
      getDisplayImageList(sampleImageUrl) || getDisplayImage(data?.imageUrl),
    sampleImagePopupUrlView: sampleImageUrl,
  }
  return updatedDataWithImage
}

const getFormattedSize = (size = {}) => {
  const dimensionsConsts = {
    height: 'H',
    width: 'W',
    depth: 'D',
    weight: 'Wt',
    other_dimension: 'OTHERS',
  }
  let sizeStr =
    Object.keys(size)
      ?.map((sObj) => {
        return size[sObj] ? dimensionsConsts[sObj] + ':' + size[sObj] : null
      })
      ?.filter((fobj) => !isNull(fobj))
      ?.join(' | ') || ''
  return sizeStr
}
export function propsResponseToSample(prop = {}, allUsers = []) {
  if (prop.type !== PROPS) {
    return prop
  }
  const { prop_data: propsData } = prop || {}
  const {
    category = {},
    sub_category = {},
    source = {},
    material = {},
    color_family = [],
    size = {},
    brand = '',
    props_specialist_user_id = '',
  } = propsData || {}
  const { name: categoryName = '', category_id: categoryId = '' } =
    category || {}
  const {
    name: subCategoryName = '',
    sub_category_id: subCategoryId = '',
    category_id: mainCategoryId = '',
    category_name: mainCategoryName = '',
  } = sub_category || {}
  const propSpecialistId =
    !isNull(props_specialist_user_id) && props_specialist_user_id !== ''
      ? props_specialist_user_id
      : prop.user_id
  const { source_name: sourceName = '', source_id: sourceId = '' } =
    source || {}
  const { material_name: materialName = '', material_id: materialId = '' } =
    material || {}
  const colorFamilyList = (color_family || [])?.map((colorObj = {}) => {
    const { color_id: colorId = '', name: colorName = '' } = colorObj
    return {
      label: colorName,
      id: colorId,
      value: colorId,
      displayName: colorId,
    }
  })
  const colorFamilyNames = (colorFamilyList || [])
    ?.map((obj) => obj.label)
    ?.join(', ')
  const propSpecialist = allUsers.find(
    (userObj) => userObj.value === propSpecialistId
  )
  const result = {
    propSpecialistId: prop.user_id,
    propSpecialist: propSpecialist?.label
      ? propSpecialist?.label
      : prop.user_id,
    propId: propsData?.prop_id,
    propDescription: propsData?.description,
    propColorFamilyName: colorFamilyNames,
    propMaterialName: propsData?.material?.material_name,
    propSourceName: propsData?.source?.source_name,
    propCategoryName: propsData?.category?.name,
    propSubcategoryName: propsData?.sub_category?.name,
    propWidth: get(size, 'width'),
    propHeight: get(size, 'height'),
    propDepth: get(size, 'depth'),
    propOtherDimension: get(size, 'other_dimension'),
    propSizeView: getFormattedSize(size) || '',
    propSize: size,
    propCost: propsData?.cost || Number(0),
    shipmentCount: propsData?.shipment_count || Number(0),
    targetBrand: propsData?.brand,
    propNotes: propsData?.studio_notes || '',
    propBundleQuantity: propsData?.bundle_quantity || null,
    isBundle: propsData?.is_bundle,
    propBundledView: propsData?.is_bundle ? 'Yes' : 'No',
    studioNotes: propsData?.studio_notes || '',
    sampleImageUrl: propsData?.image_urls || [],
    propRetailer: '',
    propCategory:
      categoryName && categoryId
        ? {
            label: categoryName,
            id: categoryId,
            value: categoryId,
            displayName: categoryId,
          }
        : null,
    propBrand: brand
      ? {
          label: brand,
          id: brand,
          value: brand,
          displayName: brand,
        }
      : null,
    propSubcategory:
      subCategoryName && subCategoryId
        ? {
            label: subCategoryName,
            id: subCategoryId,
            value: subCategoryId,
            displayName: subCategoryId,
            categoryId: mainCategoryId,
            categoryName: mainCategoryName,
          }
        : null,
    propSource:
      sourceName && sourceId
        ? {
            label: sourceName,
            id: sourceId,
            value: sourceId,
            displayName: sourceId,
          }
        : null,
    propMaterial:
      materialName && materialId
        ? {
            label: materialName,
            id: materialId,
            value: materialId,
            displayName: materialId,
          }
        : null,
    propColorFamily: colorFamilyList.length ? colorFamilyList : [],
    type: PROPS,
  }
  return result
}
export function scenicResponseToSample(scenic = {}, allUsers = []) {
  if (scenic.type !== SCENIC) {
    return scenic
  }
  const { scenic_data: scenicData, scenic_rooms = [] } = scenic || {}
  const {
    scenic_category = {},
    scenic_sub_category = {},
    scenic_source = {},
    scenic_material = {},
    color_families = [],
    scenic_channel = {},
    size = {},
    brand = '',
    scenic_user_id = '',
  } = scenicData || {}
  const scenicSpecialistId =
    !isNull(scenic_user_id) && scenic_user_id !== ''
      ? scenic_user_id
      : scenic?.user_id
  const { name: categoryName = '', category_id: categoryId = '' } =
    scenic_category || {}
  const {
    name: subCategoryName = '',
    sub_category_id: subCategoryId = '',
    category_id: mainCategoryId = '',
    category_name: mainCategoryName = '',
  } = scenic_sub_category || {}
  const { source_name: sourceName = '', source_id: sourceId = '' } =
    scenic_source || {}
  const { material_name: materialName = '', material_id: materialId = '' } =
    scenic_material || {}
  const colorFamilyList = (color_families || [])?.map((colorObj = {}) => {
    const { color_id: colorId = '', name: colorName = '' } = colorObj
    return {
      label: colorName,
      id: colorId,
      value: colorId,
      displayName: colorId,
    }
  })
  const colorFamilyNames = (colorFamilyList || [])
    ?.map((obj) => obj?.label)
    ?.join(', ')
  const unArchivedScenicRooms = (scenic_rooms || []).filter((item) => item.status === "ACTIVE")
  const roomNames = (unArchivedScenicRooms || [])?.map((obj) => obj?.name)?.join(', ')
  const { channel_id: channelId = '', channel_name: channelName = '' } =
    scenic_channel || {}
  const scenicSpecialist = allUsers.find(
    (userObj) => userObj?.value === scenicSpecialistId
  )
  const result = {
    sceniSpecialistId: scenic_user_id,
    scenicSpecialist: scenicSpecialist?.label
      ? scenicSpecialist?.label
      : scenic_user_id,
    scenicId: scenicData?.filemaker_id,
    propId: scenicData?.filemaker_id,
    scenicDescription: scenicData?.description,
    scenicColorFamilyName: colorFamilyNames,
    scenicMaterialName: scenicData?.scenic_material?.material_name,
    scenicSourceName: scenicData?.scenic_source?.source_name,
    scenicCategoryName: scenicData?.scenic_category?.name,
    scenicSubcategoryName: scenicData?.scenic_sub_category?.name,
    scenicWidth: get(size, 'width'),
    scenicHeight: get(size, 'height'),
    scenicDepth: get(size, 'depth'),
    scenicOther: get(size, 'other'),
    scenicOtherDimension: get(size, 'other_dimension'),
    scenicSizeView: getFormattedSize(size) || '',
    scenicSize: size ? size : {},
    scenicCost: scenicData?.cost || Number(0),
    shipmentCount: scenicData?.shipment_count || Number(0),
    targetBrand: scenicData?.target_brand,
    scenicBundleQuantity: scenicData?.bundle_quantity || null,
    scenicWeight: get(size, 'weight') || null,
    isBundle: scenicData?.is_bundle,
    scenicBundledView: scenicData?.is_bundle ? 'Yes' : 'No',
    scenicNotes: scenicData?.asset_notes || '',
    scenicStatus: scenicData?.asset_status || '',
    scenicImageUrl: scenicData?.image_urls || [],
    sampleImageUrl: scenicData?.image_urls || [],
    scenicAsset: scenicData?.asset_status || null,
    shipmentCount: scenicData?.shipment_count || null,
    scenicRoomNames: roomNames,
    scenicCategory:
      categoryName && categoryId
        ? {
            label: categoryName,
            id: categoryId,
            value: categoryId,
            displayName: categoryId,
          }
        : null,
    scenicBrand: scenicData?.target_brand
      ? {
          label: scenicData?.target_brand,
          id: scenicData?.target_brand,
          value: scenicData?.target_brand,
          displayName: scenicData?.target_brand,
        }
      : null,
    scenicSubcategory:
      subCategoryName && subCategoryId
        ? {
            label: subCategoryName,
            id: subCategoryId,
            value: subCategoryId,
            displayName: subCategoryId,
            categoryId: mainCategoryId,
            categoryName: mainCategoryName,
          }
        : null,
    scenicSource:
      sourceName && sourceId
        ? {
            label: sourceName,
            id: sourceId,
            value: sourceId,
            displayName: sourceId,
          }
        : null,
    scenicMaterial:
      materialName && materialId
        ? {
            label: materialName,
            id: materialId,
            value: materialId,
            displayName: materialId,
          }
        : null,
    scenicColorFamily: colorFamilyList.length ? colorFamilyList : [],
    type: SCENIC,
  }
  return result
}
export function getClosestProject(projects) {
  if (projects === null) {
    return null
  } else if (projects.length === 1) {
    return projects[0]
  }

  let earliestFutureProject = undefined
  let lastProject = undefined

  for (let index in projects) {
    if (
      projects[index].shoot_type === 'Project' &&
      moment(projects[index].shoot_start_date).isSameOrAfter(
        moment().startOf('day')
      ) &&
      earliestFutureProject === undefined
    ) {
      earliestFutureProject = projects[index]
    } else if (
      projects[index].shoot_type === 'Project' &&
      moment(projects[index].shoot_start_date).isSameOrAfter(
        moment().startOf('day')
      ) &&
      moment(projects[index].shoot_start_date).isBefore(
        moment(earliestFutureProject.shoot_start_date)
      )
    ) {
      earliestFutureProject = projects[index]
    }
    if (projects[index].shoot_type === 'Project' && lastProject === undefined) {
      lastProject = projects[index]
    } else if (
      projects[index].shoot_type === 'Project' &&
      moment(projects[index].shoot_start_date).isAfter(
        moment(lastProject.shoot_start_date)
      )
    ) {
      lastProject = projects[index]
    }
  }

  if (earliestFutureProject !== undefined) {
    return earliestFutureProject
  } else if (lastProject !== undefined) {
    return lastProject
  } else {
    return null
  }
}

export function sampleLocationResponseToLocation(location) {
  if (!isEmpty(location)) {
    return (
      ((location.building !== null && location.building) || '') +
      ((location.aisle && ' > ' + location.aisle) || '') +
      ((location.section && ' > ' + location.section) || '') +
      ((location.shelf !== null && ' > ' + location.shelf) || '') +
      ((location.container !== null && ' > ' + location.container) || '')
    )
  }
  return location
}

export function locationResponseToLocationId(location) {
  if (!isEmpty(location)) {
    return (
      ((location.building !== null && buildingNames[location.building]) || '') +
      ((location.aisle && ' > ' + location.aisle) || '') +
      ((location.section && ' > ' + location.section) || '') +
      ((location.shelf !== null && ' > ' + location.shelf) || '') +
      ((location.container !== null && ' > ' + location.container) || '')
    )
  }
  return location
}

export function filterResponseToFilter(filter) {
  return {
    lanId: filter.lan_id,
    title: filter.title,
    filters: filter.filters,
    accessType: filter.access_type,
    id: filter?.id || '',
  }
}
export function filterResponseToAOFilter(filter) {
  const { auto_order_filters = {} } = filter
  let returnObj = {}
  if (Object.entries(auto_order_filters).length > 0) {
    Object.keys(auto_order_filters).map((obj) => {
      returnObj = Object.assign({}, returnObj, {
        [obj]: {
          requestParamValue:
            listInputs.indexOf(obj) !== -1
              ? auto_order_filters[obj].join(',') || ''
              : auto_order_filters[obj],
        },
      })
      return obj
    })
  }
  return returnObj || auto_order_filters
}

export function filtersResponseToFilters(response, pageName = '') {
  const { data = {} } = response
  const { filter_list = [] } = data
  return filter_list.map((filters) => filterResponseToFilter(filters))
}

export function filtersResponseToAOFilters(response) {
  return (
    response.data.filter_list.map((filters) =>
      filterResponseToAOFilter(filters)
    )[0] || {}
  )
}
export function filtersToFiltersRequest(filterRequest, filterType = '') {
  const returnObj = {
    lan_id: filterRequest.lanId,
    title: filterRequest.title,
    filters: filterRequest.filters,
    access_type: filterRequest.accessType,
    feature: filterRequest.feature,
    auto_order_filters: filterRequest.auto_order_filters,
    id: filterRequest?.id || '',
  }
  if (filterType === 'searchInventory') {
    returnObj['search_filters'] = filterRequest?.filters
    delete returnObj['filters']
  }
  return returnObj
}

export function brandNamesResponseToSelect(response) {
  let returnArray = []
  for (let index in response?.data?.brand_names) {
    if (response?.data?.brand_names[index]) {
      returnArray.push({
        value: response?.data?.brand_names[index],
        label: response?.data?.brand_names[index],
      })
    }
  }
  return returnArray
}

export function userNamesResponseToSelect(response) {
  let returnArray = []
  for (let index in response?.data?.users) {
    if (response?.data?.users[index]) {
      returnArray.push({
        value: response?.data?.users[index]?.lan_id,
        label:
          response?.data?.users[index]?.first_name +
          ' ' +
          response?.data?.users[index]?.last_name,
      })
    }
  }
  return returnArray
}

export function departmentResponseToSelect(response) {
  let returnArray = []
  for (let index in response.data.departments) {
    if (response.data.departments[index]) {
      var label =
        response.data.departments[index].department_name !== undefined
          ? unescape(response.data.departments[index].department_name) +
            ' (' +
            response.data.departments[index].department_id +
            ')'
          : response.data.departments[index].department_id
      returnArray.push({
        value: response.data.departments[index].department_id,
        label: label,
      })
    }
  }
  return returnArray
}

export function departmentClassResponseToSelect(response) {
  let returnArray = []
  for (let index in response.data.classes) {
    if (response.data.classes[index]) {
      var label =
        response.data.classes[index].class_name !== undefined
          ? unescape(response.data.classes[index].class_name) +
            ' (' +
            response.data.classes[index].class_id +
            ')'
          : response.data.classes[index].class_id
      returnArray.push({
        value: response.data.classes[index].class_id,
        label: label.toString(),
        class_id: response?.data?.classes[index]?.class_id,
      })
    }
  }
  return returnArray
}


export function deptClassSubClassesResponseToSelect(response = {}) {
  const subClsRes = response?.data || {}
  const finalSubClsList = subClsRes?.map((subClsObj = {}) => { 
    return {
      label: `${subClsObj?.sub_class_id || subClsObj?.sub_class_name}`,
      value: `${subClsObj?.sub_class_id || subClsObj?.sub_class_name}`,
      displayName:  subClsObj?.sub_class_id
        ? `${subClsObj?.sub_class_name} - ${subClsObj?.sub_class_id || ''}`
        : `${subClsObj?.sub_class_name}`,
      group_by: subClsObj['class_name']
        ? `${subClsObj['class_id']} - ${subClsObj['class_name']}`
        : `${subClsObj['class_id']}`,
      group_by_id: subClsObj['class_id'],
      class_id: subClsObj['class_id'],
      subclass_id: subClsObj?.sub_class_id,
      id: `${subClsObj['department_id']}-${subClsObj['class_id']}-${subClsObj.label}`,
    }
  })
  return finalSubClsList || []
}

export function getFormattedSubCategories(data = [], categoryId = '') {
  let returnArray = []

  for (let index in data) {
    if (data[index].category_id.toString() === categoryId) {
      const subCategories =
        data[index]?.subCategories || data[index]?.scenic_sub_categories || []
      subCategories.map((subCategoryInstance) => {
        var label =
          subCategoryInstance.name !== undefined
            ? unescape(subCategoryInstance.name)
            : subCategoryInstance.sub_category_id
        returnArray.push({
          value: subCategoryInstance.sub_category_id,
          label: label.toString(),
        })
        return {}
      })
    }
  }
  return returnArray
}
