import * as types from './actionType'
import { getDisplayImage } from '../../util/CommonUtils'
import { findIndex, isEmpty } from 'lodash'
export const initialState = (window.Cypress && window.initialState) || {
  availableFilters: [],
  checkBoxEnabled: true,
  clickableColumn: ['description', 'launchDate'],
  columnData: [
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      align: 'center',
    },
    {
      id: 'size',
      numeric: false,
      disablePadding: false,
      label: 'Size',
      align: 'center',
    },
    {
      id: 'departmentId',
      numeric: false,
      disablePadding: false,
      label: 'Department',
      align: 'center',
    },
    {
      id: 'classId',
      numeric: false,
      disablePadding: false,
      label: 'Class',
      align: 'center',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class',
      align: 'center',
    },
    {
      id: 'brand',
      numeric: false,
      disablePadding: false,
      label: 'Brand',
      align: 'center',
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      type: 'date',
    },
    {
      id: 'vendorId',
      numeric: false,
      disablePadding: false,
      label: 'Vendor ID',
      align: 'center',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Name',
      align: 'center',
    },
  ],
  data: [],
  sizeData: [],
  fetchDataError: '',
  fetchDataRequestFailure: false,
  fetchDataRequestPending: false,
  fetchDataRequestSuccess: false,
  filters: {},
  invalidData: [],
  isVisible: false,
  item: [],
  numSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onClick: () => {},
  page: 1,
  popUpReprintLabels: [
    { key: 'tcin', label: 'TCIN' },
    { key: 'dpci', label: 'DPCI' },
    { key: 'description', label: 'Description' },
  ],
  reprintLabelPopupIsVisible: false,
  popupIsVisible: false,
  searchField: '',
  response: false,
  duplicateSamplesPending: false,
  duplicateSamplesFailure: false,
  saveFiltersFailure: false,
  saveFiltersPending: false,
  getFiltersFailure: false,
  getFiltersPending: false,
  selectedData: [],
  rowsPerPage: 100,
  totalRowsCount: 100,
  filterNFList: {},
  updateData: [],
  userId: ' ',
  historyData: [],
  historyFilters: {},
  pageName: 'autoOrderSamples',
  setColumnSettingsPending: false,
  searchedColumns: [],
  isSearchedColumns: false,
  locations: [],
  searchInventorySamplesRequestPending: false,
  searchInventorySamplesRequestFailure: false,
  removeSampleRequestPending: false,
  removeSampleRequestSuccess: false,
  refreshAutoOrderSamples: false,
  settingsLocation: '',
  autoOrderSamples: [],
  autoOrderGridColumn: [
    {
      id: 'departmentId',
      numeric: false,
      disablePadding: false,
      label: 'Department',
      align: 'center',
    },
    {
      id: 'classId',
      numeric: false,
      disablePadding: false,
      label: 'Class',
      align: 'center',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class',
      align: 'center',
    },
    {
      id: 'brandName',
      numeric: false,
      disablePadding: false,
      label: 'Brand Name',
      align: 'center',
    },
    {
      id: 'shipToLocation',
      numeric: false,
      disablePadding: false,
      label: 'Ship To Location',
      align: 'center',
    },
    {
      id: 'size',
      numeric: false,
      disablePadding: false,
      label: 'Size',
      align: 'center',
    },
  ],
  csvHeaders: [
    {
      key: 'departmentId',
      label: 'Department',
    },
    {
      key: 'classId',
      label: 'Class',
    },
    {
      key: 'brandName',
      label: 'Brand Name',
    },
    {
      key: 'shipToLocation',
      label: 'Ship To Location',
    },
    {
      key: 'size',
      label: 'Size',
    },
  ],
}

function getProjectNames(projects) {
  const projectNames = projects.map(function (obj) {
    return {
      projectName: obj.name,
    }
  })
  return projectNames.map((x) => x.projectName).join()
}

export default function AutoOrderSamples(state = initialState, action = {}) {
  const { payload = '' } = action
  switch (action.type) {
    case types.AUTO_ORDER_SET_FILTER_NOTFOUND_LIST: {
      return Object.assign({}, state, {
        ...state,
        filterNFList: action.payload.filterNFList,
      })
    }
    case types.GET_AUTO_ORDER_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        fetchDataRequestPending: action.payload.pending,
      })
    }
    case types.GET_AUTO_ORDER_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        fetchDataRequestFailure: true,
      })
    }
    case types.GET_AUTO_ORDER_SAMPLES_SUCCESS: {
      const payloadData = action.payload.data.map((item) => ({
        ...item,
        imagePopupUrl: item.imageUrl,
        imageUrl:
          item.imageUrl !== undefined &&
          item.imageUrl !== null &&
          item.imageUrl !== ''
            ? getDisplayImage(item.imageUrl)
            : 'Image Not Found',
        projectNames:
          item.projects !== null ? getProjectNames(item.projects) : null,
        shippingInfo:
          item.sampleStatus === 'Sample Shipped' ? (
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              href={
                `https://www.google.com/search?q=` +
                encodeURI(`${item.carrier} ${item.trackingNum}`)
              }
              rel="noreferrer"
            >
              {item.carrier} {item.trackingNum}
            </a>
          ) : (
            ''
          ),
      }))
      const newState = Object.assign({}, state, {
        ...state,
        data: payloadData,
        fetchDataRequestSuccess: true,
      })
      return newState
    }
    case types.RESET_FETCH_DATA_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        fetchDataRequestSuccess: false,
      })
    }
    case types.REMOVE_AUTO_ORDER_SAMPLES_PENDING: {
      const {
        payload: { pending },
      } = action
      return Object.assign({}, state, {
        removeSampleRequestPending: pending,
      })
    }
    case types.REMOVE_AUTO_ORDER_SAMPLES_FULFILLED: {
      const {
        payload: { status },
      } = action
      return Object.assign({}, state, {
        removeSampleRequestSuccess: status,
      })
    }
    case types.REFRESH_AUTO_ORDER_SAMPLES: {
      const {
        payload: { status },
      } = action
      return Object.assign({}, state, {
        refreshAutoOrderSamples: status,
      })
    }
    case types.UNSET_SELECTED_DATA: {
      return Object.assign({}, state, {
        selectedData: [],
        numSelected: 0,
      })
    }
    case types.AUTO_ORDER_SET_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.AUTO_ORDER_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.AUTO_ORDER_SET_TOTAL_ROWS_COUNT: {
      return Object.assign({}, state, {
        totalRowsCount: action.payload.totalRowsCount,
      })
    }
    case types.FETCH_AUTO_ORDER_LOADING: {
      return Object.assign({}, state, {
        fetchAutoORderGridLoading: payload,
      })
    }
    case types.AUTO_ORDER_SET_CHECKBOX: {
      const { selectedData = {} } = state
      const {
        payload: { selected = {} },
      } = action
      const { pegasusId: selectedPegId = '' } = selected
      const selectedIndex = findIndex(selectedData, function (obj = {}) {
        return obj.pegasusId === selectedPegId
      })

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.AUTO_ORDER_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedIds = {} },
      } = action
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.pegasusId === i.pegasusId
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.pegasusId === i.pegasusId
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.AUTO_ORDER_OPEN: {
      return Object.assign({}, state, {
        ...state,
        settingsLocation: payload,
      })
    }
    case types.UPDATE_SIZE_DATA: {
      return Object.assign({}, state, {
        ...state,
        sizeData: action.payload,
      })
    }
    case types.FETCH_AUTOORDER_SAMPLES_SUCCESS: {
      const { payload: { autoOrderSamples = [], filters = {} } = {} } = action
      const result = Object.keys(filters).reduce((prev, orCondition) => {
        const filterArr = prev.length > 0 ? prev : autoOrderSamples
        return filterArr.filter((obj) => {
          return filters[orCondition] &&
            filters[orCondition]['requestParamValue'] &&
            filters[orCondition]['requestParamValue'].length > 0
            ? filters[orCondition]['requestParamValue'].includes(
                obj[orCondition]
              )
            : true
        })
      }, [])
      // var brandFilter =
      //   (filters['brandId'] && filters['brandId']['requestParamValue']) || []
      // var departmentFilter =
      //   (filters['org_department_id'] &&
      //     filters['org_department_id']['requestParamValue']) ||
      //   []
      // var result = autoOrderSamples.filter(function (e) {
      //   return (
      //     brandFilter.includes(e.brandName) ||
      //     departmentFilter.includes(e.departmentId)
      //   )
      // })
      // If no filter (ex: first time load) is applied then return the whole autoorder response because result will be empty array
      const isAnyFiterApplied = !isEmpty(filters)
      return Object.assign({}, state, {
        ...state,
        autoOrderSamples: isAnyFiterApplied ? result : autoOrderSamples,
        csvCompleteAutoOrderSamples: autoOrderSamples,
      })
    }
    default:
      return state
  }
}
