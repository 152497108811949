import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig
export function createSamples(samples = []) {
  return axios.post(
    url + 'samples',
    {
      samples: samples,
    },
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function updateOrder(orderDetails = []) {
  return axios.post(
    url + 'samples/bulk_samples',
    {
      samples: orderDetails,
    },
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function promoteSamplesToOrder(samples = []) {
  return axios.post(
    url + 'samples/order_completions',
    {
      pegasus_ids: samples,
    },
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function fetchSamplesByVendor(sampleStatus, page, rowsPerPage) {
  let params
  if (!page || !rowsPerPage) {
    params = {
      status: sampleStatus,
      key: apiKey,
    }
  } else {
    params = {
      status: sampleStatus,
      key: apiKey,
      page: page,
      per_page: rowsPerPage,
    }
  }
  return axios.get(url + 'samples/vendor_samples', {
    params: params,
  })
}

export function fetchRequestedSamplesByVendor(params) {
  return axios.get(url + 'samples/vendor_samples', {
    params: params,
  })
}

export function fetchRequestedSamplesByVendorNew(params) {
  return axios.get(url + 'samples/v2/vendor_samples', {
    params: params,
  })
}

export function fetchSamplesCsvByVendors(sampleStatus) {
  return axios.get(url + 'samples/v2/vendor_samples', {
    headers: { Accept: 'text/csv' },
    params: {
      status: sampleStatus,
      key: apiKey,
    },
  })
}

export function fetchInventorySamples() {
  return axios.get(url + 'search', {
    params: {
      key: apiKey,
    },
  })
}

export function fetchSamplesByUser(params) {
  return axios.get(url + 'search', {
    params: params,
  })
}

export function fetchSamplesByShipment(shipmentId) {
  return axios.get(url + 'samples/shipments/' + shipmentId, {
    params: {
      key: apiKey,
    },
  })
}
export function fetchSamplesByProjectId(projectId) {
  return axios.get(url + 'samples/projects/' + projectId, {
    params: {
      key: apiKey,
    },
  })
}

export const getSamples = (pegasusIds = [], page, rowsPerPage) => {
  if (pegasusIds.length > 0) {
    let params
    if (!page || !rowsPerPage) {
      params = {
        key: apiKey,
      }
    } else {
      params = {
        key: apiKey,
        page: page,
        per_page: rowsPerPage,
      }
    }
    return axios.post(
      url + 'samples/multi_samples',
      {
        pegasus_ids: pegasusIds,
      },
      {
        headers: { 'Content-Type': 'application/json' },
        params: params,
      }
    )
  }
  return true
}

export const getRelatedSamples = (pegasusId) => {
  return axios.get(url + 'samples/related_samples/' + pegasusId, {
    params: {
      key: apiKey,
    },
  })
}

export function cancelSamples(pegasusIds) {
  return axios.post(
    url + 'samples/cancellations',
    {
      pegasus_ids: pegasusIds,
    },
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        key: apiConfig.apiKey,
      },
    }
  )
}

export function duplicateSamples(sample = {}) {
  return axios.post(url + 'samples/duplicates', sample, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function duplicateSamplesAS(samples = []) {
  return axios.post(url + 'samples/duplicates', samples, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const checkinSamples = (samplesWithLaunchDates = [], location) => {
  return axios.post(
    url + 'samples/locations',
    {
      samples: samplesWithLaunchDates,
      location: location,
    },
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export const assignLocation = (
  pegasusIds = [],
  location = '',
  searchedLocations = [],
  moveType = 'S',
  operation = 'generic'
) => {
  let paramsObj = {
    location: location,
  }
  if (moveType === 'C') {
    paramsObj['container_ids'] = searchedLocations
  } else {
    paramsObj['pegasus_ids'] = pegasusIds
  }
  return axios.post(url + 'samples/inventory_locations', paramsObj, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
      operation,
    },
  })
}

export const getLocationSamples = (locationIds = [], moveType = '') => {
  return axios.post(
    url + 'samples/location_samples',
    {
      location_ids: locationIds,
      move_type: moveType,
    },
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export const validateSamples = (request) => {
  return axios.post(url + 'samples/validations', request, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function orderSamples(orderDetails = []) {
  return axios.post(
    url + 'samples/request_completions',
    {
      samples: orderDetails,
    },
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function autoOrderSamples(orderDetails = []) {
  return axios.post(
    url + 'samples/auto_order_completions',
    {
      samples: orderDetails,
    },
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiConfig.apiKey,
      },
    }
  )
}

export function removeAutoOrderSamples(orderDetails = []) {
  return axios.post(
    apiConfig.managements.url + 'samples/auto_order_cancellations',
    {
      pegasus_ids: orderDetails,
    },
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiConfig.apiKey,
      },
    }
  )
}

export function fetchAutoOrderGrid(params = {}) {
  const currentApi = apiConfig.items.graphql_url + 'auto_order_grid/v2'
  return axios.get(currentApi, {
    params: {
      key: apiConfig.apiKey,
      ...params
    },
  })
}

export function fetchSamplesByUserPost(params) {
  const {
    requestParams,
    requestPayload = {
      include_filters: {},
      exclude_filters: {},
    },
  } = params
  return axios.post(url + 'search', requestPayload, {
    params: requestParams,
  })
}

export function getSizesApi() {
  return axios.get(apiConfig.items.graphql_url + 'size', {
    params: {
      key: apiConfig.apiKey,
    },
  })
}
