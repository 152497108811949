import { useSelector, useDispatch } from 'react-redux'
import * as React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Box from '@mui/material/Box'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, SwipeableDrawer } from '@material-ui/core'
import { findIndex } from 'lodash'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@mui/material/IconButton'
import { CloseRounded } from '@mui/icons-material'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'
import { columnData } from './Constants'
import BottomBar from 'components/BottomBar'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import copy from 'copy-to-clipboard'
import ShowIf from 'components/ShowIf'
import {
  getProjectPicklist,
  deleteItemFromPicklist,
  downloadCsv,
  setProjectPicklistData
} from 'store/projectPicklist/actionCreator'
import DeleteIcon from '@mui/icons-material/Delete'
import { SortAlphaNum, sortDataByPegLoc } from 'util/CommonUtils'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '60px',
    alignItems: 'center',
  },
  defaultButton: {
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '20px 269px',
  },
  unAuthContent: {
    textAlign: 'center',
  },
  containerStyle: {
    paddingBottom: 10,
    float: 'right',
  },
  appBar: {
    backgroundColor: 'white',
    position: 'relative',
    height: '35px',
    textAlign: 'center',
    width: '100%',
    float: 'left',
    boxShadow: 'none',
  },
  appBarFlyout: {
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    height: '55px',
    textAlign: 'center',
    width: '100%',
    boxShadow: 'none',
  },
  popoverText: {
    float: 'left',
    color: 'black',
    fontSize: 15,
    fontWeight: 'bold',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '34px',
    margin: '0',
    color: '#CC0000',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '60px',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '1.8em 10px 2em',
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
    marginTop: '5px',
  },
  drawer: {
    '& .MuiDrawer-paperAnchorBottom': {
      position: 'fixed',
      left: '65.3%',
    },
  },
  uploadButton: {
    maxWidth: '400px',
    width: 'auto',
    margin: theme.spacing(1),
  },
}))
export default function ProjectPickList(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { projectId = '', getShootTypeSamples = () => {} } = props
  const [selectedData, setSelectedData] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(Number(10))
  const [page, setPage] = React.useState(1)
  const { picklistData, picklistDataLoading, totalRowsCount } = useSelector(
    ({
      projectPicklist: {
        picklistData = [],
        picklistDataLoading = false,
        totalRowsCount = 10,
      },
    }) => {
      return {
        picklistData,
        picklistDataLoading,
        totalRowsCount,
      }
    }
  )
  const { pickListSideNav } = useSelector(
    ({ newShootType: { pickListSideNav = false } }) => {
      return { pickListSideNav }
    }
  )
  React.useEffect(() => {
    dispatch(getProjectPicklist(projectId, page, rowsPerPage))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    dispatch(getProjectPicklist(projectId, page, rowsPerPage))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage])

  const { handlePickListAction = () => {} } = props
  const dataFieldKeys = columnData.map((column) => column.id)
  const checkBoxChange = (dataValue = {}) => {
    const selectedIndex = selectedData
      .map((e) => e.pegasusId)
      .indexOf(dataValue.pegasusId)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedData, dataValue)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedData.slice(1))
    } else if (selectedIndex === selectedData.length - 1) {
      newSelected = newSelected.concat(selectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      )
    }
    setSelectedData([...newSelected])
  }
  const onCheckBoxHeaderChange = (dataValue = []) => {
    let newSelected = []
    if (dataValue.length > selectedData.length) {
      newSelected = [...dataValue]
    } else if (dataValue.length === selectedData.length) {
      newSelected = []
    }
    setSelectedData([...newSelected])
  }
  const toggleOpen = () => {
    if (selectedData.length !== 0) {
      return true
    } else {
      return false
    }
  }
  const deleteItemConfirm = () => {
    const pegasusIds = selectedData.map((obj) => obj?.pegasusId)
    dispatch(
      deleteItemFromPicklist(
        pegasusIds,
        projectId,
        page,
        rowsPerPage,
        getShootTypeSamples()
      )
    )
    setSelectedData([])
  }
  const onCopytoClipboard = () => {
    copy(selectedData.map((item) => item?.pegasusId || '').join('\r\n'), {
      format: 'text/plain',
    })
  }
  const onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }
  const onSortSelectedChange = (order, orderBy, orderType) => {
    const dataComparator = orderBy === 'location' ? sortDataByPegLoc(order, 'sortLocation') : SortAlphaNum(order, orderBy)
    let dataCopy = [...picklistData]
    const sortedData = dataCopy.sort(dataComparator)
    dispatch(setProjectPicklistData(sortedData))
  }
  const renderSwipeableDrawer = () => {
    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={selectedData.length > 0}
        onOpen={() => null}
        onClose={() => null}
        className={classes.drawer}
        showClearAll={selectedData.length > 0}
        onClearAllClick={() => {
          setSelectedData([])
        }}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer}>
            <Typography
              variant="body1"
              component="body1"
              className={classes.defaultButton}
            >
              {selectedData.length} Item(s) Selected
            </Typography>
            <Button
              className={classes.defaultButton}
              startIcon={<DeleteIcon />}
              onClick={() => deleteItemConfirm()}
            >
              Remove
            </Button>
            <Button
              id="editSelectedItems"
              data-id="Edit Samples"
              className={classes.defaultButton}
              startIcon={<ContentCopyIcon />}
              onClick={onCopytoClipboard}
            >
              Copy To Clipboard
            </Button>
          </Grid>
        </div>
      </BottomBar>
    )
  }
  const renderPickListFlyout = () => {
    return (
      <Grid xs={12}>
        <FlexibleTable
          columnData={columnData}
          data={picklistData}
          dataFieldKeys={dataFieldKeys}
          cascadeFieldKeys={[]}
          checkBoxEnabled
          onCheckBoxChange={checkBoxChange}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          selected={selectedData}
          onSelected={onSelected}
          enableDownload
          downloadFunction={()=> dispatch(downloadCsv(projectId))}
          paginationAtApiLevel={true}
          totalRowsCount={totalRowsCount}
          isLoading={picklistDataLoading}
          onCheckBoxHeaderChange={onCheckBoxHeaderChange}
          enableSearch
          onSortSelectedChange={onSortSelectedChange}
        />
        <ShowIf condition={toggleOpen()}>{renderSwipeableDrawer()}</ShowIf>
      </Grid>
    )
  }

  return (
    <Grid container xs={12}>
      <SwipeableDrawer
        anchor="right"
        open={pickListSideNav}
        onClose={() => handlePickListAction()}
      >
        <Box
          sx={{
            width: 600,
          }}
          role="presentation"
        >
          <Box sx={{ width: 'auto', minWidth: 400 }} role="presentation">
            <AppBar className={classes.appBarFlyout}>
              <div className={classes.pageHeader}>
                <Typography variant="h4" gutterBottom data-cy="projectsTitle">
                  Project Picklist
                  <IconButton style={{ float: 'right' }}>
                    <CloseRounded
                      style={{ color: 'white' }}
                      onClick={() => handlePickListAction()}
                    />
                  </IconButton>
                </Typography>
              </div>
            </AppBar>
            {renderPickListFlyout()}
          </Box>
        </Box>
      </SwipeableDrawer>
    </Grid>
  )
}
