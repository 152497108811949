import { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import EditIcon from '@mui/icons-material/Edit'
import {
  Dialog,
  Tab,
  Tabs,
  Typography,
  DialogTitle,
  DialogContent,
  // DialogActions,
  // Button,
  IconButton,
  Button,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { PROPS, SCENIC } from 'enums/Tabs'

function TabContainer({ children, dir, classes }) {
  return (
    <Typography component="div" dir={dir} className={classes.scrollList}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
}

const styles = (theme) => ({
  content: { padding: '16px' },
  scrollList: {
    padding: '0px 8px 8px 8px',
    overflowY: 'auto',
    maxHeight: '467px',
    '&::-webkit-scrollbar': {
      width: 9,
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#8d8d8d',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
    },
    // /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#8d8d8d',
      border: '1px solid #8d8d8d',
    },
  },
  tabRoot: {
    minWidth: '135px',
  },
  tabRootWithProps: {
    minWidth: '112px',
  },
  SwipeableViews: {
    overflowY: 'hidden',
    maxHeight: '467px',
    marginTop: '25px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  editButton: {
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(2),
  },
})

class TabbedPopUp extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { value: 0 }
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  handleChangeIndex = (index) => {
    this.setState({ value: index })
  }

  handleClose = () => {
    this.props.onClose()
  }

  render = () => {
    const {
      classes,
      data,
      dataTabLabels,
      isVisible,
      theme,
      title = '',
      maxWidth = 'sm',
      fullWidth = false,
      onClose,
      currentTabValue = '',
      navigate = () => {},
      sampleStatus = '',
      pegasusId = '',
      history={}
    } = this.props
    return (
      <Dialog
        open={isVisible}
        onClose={this.handleClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        <DialogTitle>
          <Grid container>
            <Typography variant="h6">{!isEmpty(title) && title}</Typography>
            {currentTabValue === PROPS ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                className={classes.editButton}
                disabled={sampleStatus !== 'SAMPLE_RECEIVED'}
                onClick={() => navigate(`addProp/editProp/${pegasusId}`)}
              >
                {'Edit Prop'}
              </Button>
            ) : currentTabValue === SCENIC && (
              <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              className={classes.editButton}
              disabled={sampleStatus !== 'SAMPLE_RECEIVED'}
              onClick={() =>history.push(`/searchInventory/scenic/addScenic/editScenic/${pegasusId}`)}
            >
              {'Edit Scenic'}
            </Button>
            )
  }
          </Grid>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {dataTabLabels.map((label) => (
              <Tab
                key={label}
                label={label}
                classes={{
                  root:
                    dataTabLabels.length === 5
                      ? classes.tabRootWithProps
                      : classes.tabRoot,
                }}
              />
            ))}
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
            className={classes.SwipeableViews}
          >
            {dataTabLabels.map((label) => (
              <TabContainer key={label} dir={theme.direction} classes={classes}>
                {data.get(label)}
              </TabContainer>
            ))}
          </SwipeableViews>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    )
  }
}

TabbedPopUp.defaultProps = {
  data: new Map(),
  dataTabLabels: [],
  isVisible: true,
  onClose: () => {},
  title: '',
}

TabbedPopUp.propTypes = {
  data: PropTypes.object, // A Map where each key maps to the data to display on each tab
  dataTabLabels: PropTypes.array,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
}
export default withStyles(styles, { withTheme: true })(TabbedPopUp)

/** The prop called "data" provided to this component will map each TabLabel to a tab, and the associated value in the map
 *  to the content of each tab
 */
