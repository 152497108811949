import { isEmpty } from 'lodash'
import { ValidationOperation } from '../enums/validationOperations'
import { sampleStatuses } from '../util/ConstUtils'
import * as projectMapper from '../mappers/projectMapper'
import * as collectionMapper from '../mappers/collectionMapper'
import { ShootTypes } from '../enums/shootTypes'
import moment from 'moment'
import {
  DATE_FORMAT,
  PAYLOAD_DATE_FORMAT,
  convertTableDateFormat,
  sortByDate,
  getSampleIDPrefix,
  getDisplayName,
} from '../util/CommonUtils'
import { requestedSampleStatuses } from '../util/ConstUtils'
import { getPegasusID } from 'util/CommonUtils'
import { PROPS, SAMPLE, SCENIC } from 'enums/Tabs'
import {
  propsResponseToSample,
  scenicResponseToSample,
  updateMappedImage,
} from 'mappers/inventoryMapper.js'

/**
 * Utilities for mapping between data schemas b/w the front end and the back end
 */

/**
 * Maps internal sample data to sample create requests
 * @param samples
 * @returns a list of sample create requests
 */
export function samplesToSampleCreateRequests(samples = []) {
  return samples.map((sample) => sampleToSampleCreateRequest(sample))
}

export function sampleToSampleCreateRequest(sampleData) {
  return {
    tcin: sampleData.tcin,
    dpci: sampleData.dpci,
    item_quantity:
      sampleData.quantity === 0 || sampleData.quantity === undefined
        ? 1
        : sampleData.quantity,
    ship_to_loc: sampleData.shipTo,
    sample_due_date: moment(sampleData.dueDate).format(PAYLOAD_DATE_FORMAT),
    vendor_email_id: sampleData.vendorEmail || '',
    bp_ids: sampleData?.vendorBPIds || [],
    user_id: sampleData.userId,
    vendor_notes: sampleData.vendorNotes,
    studio_notes: sampleData.studioNotes,
    vendor_id: sampleData.vendorId === null ? 0 : sampleData.vendorId,
    shipment_id: sampleData.shipmentId,
    sample_status: sampleData.sampleStatus,
    brand: sampleData.manufacturerBrand,
    launch_date: moment(sampleData.launchDate).format(PAYLOAD_DATE_FORMAT),
    project: sampleData.project,
    brand_id: sampleData.brandId === null ? 0 : sampleData.brandId,
    is_owned_brand: sampleData.isOwnedBrand,
    upc: sampleData.barcodeNumber,
    manufacturer_style: sampleData.manufacturerStyle,
    sub_class_id: sampleData.subClassId || '',
  }
}

/**
 * Maps internal sample data to auto order sample requests
 * @param samples
 * @returns a list of sample create requests
 */
export function samplesToSampleAutoOrderRequests(samples = []) {
  return samples.map((sample) => sampleToSampleAutoOrderRequest(sample))
}

export function sampleToSampleAutoOrderRequest(sampleData = {}) {
  sampleData = {
    ...sampleData,
    ...sampleData.item_data,
    ...sampleData.sample_data,
  }
  const { pegasusId = '' } = sampleData
  return {
    user_id: sampleData.userId,
    pegasus_id: pegasusId.substring(2, pegasusId.length),
    vendor_email_id: sampleData.vendorEmail || '',
    bp_ids: sampleData?.vendorBPIds || [],
    sub_class_id: sampleData.subClassId || '',
    vendor_notes: sampleData.vendorNotes,
    studio_notes: sampleData.studioNotes,
    sample_due_date: moment(sampleData.dueDate).format(PAYLOAD_DATE_FORMAT),
    ship_to_loc: sampleData.shipTo,
    project: sampleData.project,
    item_quantity:
      sampleData.quantity === 0 || sampleData.quantity === undefined
        ? 1
        : sampleData.quantity,
  }
}

export function samplesToDeleteSampleNotesAndProject(samples = []) {
  return samples
    .map((sample) => sampleToSampleCreateRequest(sample))
    .map((sample) =>
      Object.assign({}, sample, {
        studio_notes: '',
        vendor_notes: '',
      })
    )
}

/**
 * Converts internal sample model to Order API sample update request
 * @param samples
 * @returns a list of sample update requests
 */
export function samplesToSampleUpdateRequest(samples = []) {
  return samples.map((sample) => sampleToSampleUpdateRequest(sample))
}

export function sampleToSampleUpdateRequest(sampleData) {
  return {
    pegasus_id: sampleData.pegasusId.substring(2, sampleData.pegasusId.length),
    vendor_email_id: sampleData.vendorEmail || '',
    ship_to_loc: sampleData.shipToLoc,
    sample_due_date: moment(sampleData.dueDate).format(PAYLOAD_DATE_FORMAT),
    vendor_notes: sampleData.vendorNotes,
    studio_notes: sampleData.studioNotes,
  }
}

/**
 * Converts internal sample model to Order API sample update request
 * @param samples
 * @returns a list of sample update requests
 */
export function samplesToSampleUpdateRequestMyorder(samples = []) {
  return samples.map((sample) => sampleToSampleUpdateRequestMyorder(sample))
}

export function sampleToSampleUpdateRequestMyorder(sampleData) {
  const {
    pegasusId = '',
    vendorEmail = '',
    shipTo = '',
    dueDate = '',
    vendorNotes = '',
    studioNotes = '',
    projects = [],
  } = sampleData
  return {
    pegasus_id: pegasusId.substring(2, pegasusId.length),
    vendor_email_id: vendorEmail || '',
    ship_to_loc: shipTo,
    sample_due_date: moment(dueDate).format(PAYLOAD_DATE_FORMAT),
    vendor_notes: vendorNotes,
    studio_notes: studioNotes,
    project: projects.length ? projects[0].id : '',
  }
}

/**
 * Converts internal sample model to Order API sample update request
 * @param samples
 * @returns a list of sample update requests
 */
export function samplesToSearchUpdateRequest(samples = []) {
  return samples.map((sample) => sampleToSearchUpdateRequest(sample))
}

export function sampleToSearchUpdateRequest(sampleData = {}) {
  const {
    pegasusId = '',
    dispositionNotes = '',
    warehouseNotes = '',
    disposition_override_date = '',
  } = sampleData
  return {
    pegasus_id: pegasusId.substring(2, pegasusId.length),
    disposition_notes: dispositionNotes,
    disposition_override_date: disposition_override_date
      ? moment(disposition_override_date).format(PAYLOAD_DATE_FORMAT)
      : null,
    warehouse_notes: warehouseNotes,
  }
}

export function multiSamplesResponseToSamples(response) {
  return response.map((sample) =>
    sampleResponseToSample({
      ...sample,
      ...sample.item_data,
      ...sample.sample_data,
    })
  )
}

export function filteredSamplesResponseToSamples(response) {
  return response.samples
    .filter((sample) => sample.sample_status === 'SAMPLE_SHIPPED')
    .map((sample) =>
      sampleResponseToSample({
        ...sample,
        ...sample.item_data,
        ...sample.sample_data,
      })
    )
}

/**
 * Converts order api sample response to the internal sample/order model
 * @param response
 * @returns a list of samples
 */
export function samplesResponseToSamples(response = [], currentProjectId = '') {
  return response.data.samples.map((sample) =>
    sampleResponseToSample(
      {
        ...sample,
        ...sample.item_data,
        ...sample.sample_data,
      },
      currentProjectId
    )
  )
}

/**
 * Sort order api sample response by modified date
 * @param response
 * @returns a list of samples
 */
export function samplesResponseToReqSamples(response = {}) {
  const {
    data: { requested_samples = [] },
  } = response
  const formattedReqSamples = requested_samples?.map((obj) => {
    if (!isEmpty(obj?.sms_partners)) {
      const { sms_partners = [] } = obj
      const smsPartners = sms_partners
        ?.map((obj) => getDisplayName(obj))
        ?.join(', ')
      return Object.assign({}, obj, { smsPartners: smsPartners })
    }
    return obj
  })
  const requestedSamples = sortByDate(
    formattedReqSamples,
    'modified_ts',
    'desc'
  )
  return requestedSamples
}

/**
 * Converts order api sample response to the internal sample/order model
 * @param response
 * @returns a list of samples
 */
export function samplesResponseToSamplesNew(response = []) {
  return response.map((sample) => sampleResponseToSample(sample))
}

/**
 * Converts order api sample response to the internal sample/order model
 * @param response
 * @returns a list of samples
 */
export function samplesResponseToSamplesCheckIn(response = []) {
  return response.data.samples.map((sample) => {
    const { projects: currentSampleProjects = [] } = sample
    const projectOnly = (currentSampleProjects || []).filter(
      (x) => x.shoot_type === ShootTypes.PROJECT
    )
    const formattedProjects =
      projectMapper.projectResponseToProjects(projectOnly)
    const formattedProjectsSort = sortByDate(
      formattedProjects,
      'shootStartDate',
      'asc'
    )
    const returnedResponse = Object.assign({}, sampleResponseToSample(sample), {
      projects: !isEmpty(currentSampleProjects) ? formattedProjectsSort : null,
    })
    return returnedResponse
  })
}
export function requestedSampleResponseToRequestedSample(requestedSample) {
  return requestedSample.map((requestedSample) => {
    requestedSample.updateDate = convertTableDateFormat(
      requestedSample.modified_ts,
      DATE_FORMAT
    )
    requestedSample.lifecycleState = requestedSampleStatuses
      .filter((status) => requestedSample.lifecycleState === status.key)
      .map((status) => status.value)
      .toString()
    return requestedSample
  })
}

export function sampleResponseToSample(sample, currentProjectId) {
  sample = {
    ...sample,
    ...sample.item_data,
    ...sample.sample_data,
  }
  const { pegasus_id = '', type = SAMPLE } = sample
  let result = {
    addedToPicklist: sample?.added_to_picklist ? 'Yes' : 'No',
    pegasusId: getSampleIDPrefix(pegasus_id, type),
    sampleId: sample.pegasus_id,
    orderNum: sample.order_num,
    sample_status: sample.sample_status,
    subClassId: sample.sub_class_id || '',
    subClassName: sample.sub_class_name || '',
    sampleStatus: sampleStatuses
      .filter((status) => sample.sample_status === status.key)
      .map((status) => status.value)
      .toString(),
    tcin: sample.tcin,
    dpci: sample.dpci,
    userId: sample.user_id,
    vendorId: sample.vendor_id,
    vendorEmail: sample.vendor_email_id,
    vendorBPIds: sample.bp_ids,
    shipTo: sample.ship_to_loc,
    shipToDestination:
      sample.ship_to_destination !== null
        ? sample.ship_to_destination
        : sample.ship_to_loc,
    shipToLocToPrint: sample.ship_to_loc,
    dueDate: convertTableDateFormat(sample.sample_due_date, DATE_FORMAT),
    brand: sample.brand || '',
    vendorNotes: sample.vendor_notes || '',
    studioNotes: sample.studio_notes || '',
    shipmentId: sample.shipment_id,
    createDate: convertTableDateFormat(sample.create_ts, DATE_FORMAT),
    updateDate: convertTableDateFormat(sample.update_ts, DATE_FORMAT),
    sampleUpdateDate: convertTableDateFormat(sample.update_ts, DATE_FORMAT),
    updateTs: moment(sample.update_ts).format('x'),
    sampleLaunchDate: convertTableDateFormat(sample.launch_date, DATE_FORMAT),
    location: sampleLocationResponseToLocation(sample.location),
    projects:
      sample.projects !== undefined && sample.projects !== null
        ? projectMapper.projectResponseToProjects(
            sample.projects.filter((x) => x.shoot_type === ShootTypes.PROJECT)
          )
        : null,
    collections:
      sample.projects !== undefined && sample.projects !== null
        ? collectionMapper.collectionResponseToCollections(
            sample.projects.filter(
              (x) => x.shoot_type === ShootTypes.COLLECTION
            )
          )
        : null,
    receivedDate: sample.received_date
      ? convertTableDateFormat(sample.received_date, DATE_FORMAT)
      : '',
    dispositionNotes:
      sample.disposition_notes !== null ? sample.disposition_notes : '',
    warehouseNotes:
      sample.warehouse_notes !== null ? sample.warehouse_notes : '',
    brandId: sample.brand_id,
    building: sample.building || '',
    trackingNum: sample.tracking_number,
    carrier: sample.carrier,
    shippedDate: convertTableDateFormat(sample.shipped_date, DATE_FORMAT),
    digital_markdown_date: sample.digital_markdown_date
      ? convertTableDateFormat(sample.digital_markdown_date, DATE_FORMAT)
      : '',
    disposition_override_date: sample.disposition_override_date
      ? convertTableDateFormat(sample.disposition_override_date, DATE_FORMAT)
      : '',
    store_markdown_date: sample.store_markdown_date
      ? convertTableDateFormat(sample.store_markdown_date, DATE_FORMAT)
      : '',
    disposition_date: sample.disposition_date
      ? convertTableDateFormat(sample.disposition_date, DATE_FORMAT)
      : '',
    container: getContainerName(sample.location),
    manufacturerStyle: sample.manufacturer_style || '',
    otherProjects:
      !isEmpty(sample.projects) &&
      !isEmpty(getOtherProjectsList(sample.projects, currentProjectId))
        ? getOtherProjectsList(sample.projects, currentProjectId)
        : '-',
    itemType: sample?.type || 'SAMPLE',
  }

  result.shipmentSampleStatus = result.sampleStatus // For shipment only

  const { sample_shoot_details: sampleShootDetails } = sample
  if (sample.type === PROPS) {
    const propsData = propsResponseToSample(sample)
    result = { ...result, ...propsData }
    result = updateMappedImage({ ...result })
  }
  if (sample.type === SCENIC) {
    const scenicData = scenicResponseToSample(sample)
    result = { ...result, ...scenicData }
    result = updateMappedImage({ ...result })
  }

  if (sampleShootDetails) {
    // Only for shippment sample/props mapping
    const {
      added_to_shipment_date: addedToShipmentDate,
      added_by: addedBy,
      is_bundle,
      bundle_count,
      is_shipped: isShippedEntity,
      shipped_date,
      id,
      recent_shipment_id: mostRecentShipment,
    } = sampleShootDetails
    result.addedToShipmentDateTime = addedToShipmentDate
    result.addedToShipmentDate = addedToShipmentDate
      ? convertTableDateFormat(addedToShipmentDate, DATE_FORMAT)
      : ''
    result.addedBy = addedBy?.firstName + ' ' + addedBy?.lastName
    result.isBundle = is_bundle
    result.shipmentBundleCount = bundle_count
    result.isShippedEntity = isShippedEntity
    result.sample_shoot_id = id
    result.mostRecentShipment = mostRecentShipment
    result.shippedDate = shipped_date
      ? convertTableDateFormat(shipped_date, DATE_FORMAT)
      : ''
    if (isShippedEntity) {
      result.shipmentSampleStatus = 'Shipped'
    }
  }
  return result
}

const getOtherProjectsList = (projectsList, currentProjectId) => {
  return projectsList
    .filter(
      (x) =>
        x.shoot_type === ShootTypes.PROJECT && x.project_id !== currentProjectId
    )
    .map((project) => project.name)
    .join(', ')
}

export function sampleLocationResponseToLocation(location) {
  if (!isEmpty(location)) {
    return (
      ((location.building !== null && location.building) || '') +
      ((location.aisle && ' > ' + location.aisle) || '') +
      ((location.section && ' > ' + location.section) || '') +
      ((location.shelf !== null && ' > ' + location.shelf) || '') +
      ((location.container !== null && ' > ' + location.container) || '')
    )
  }
  return location
}
export function getContainerName(location) {
  let containerName = ''
  if (!isEmpty(location)) {
    const { container = '' } = location
    containerName = container
  }
  return containerName
}

export function createValidationRequest(
  pegasusIds = [],
  location = '',
  operation = ValidationOperation.CHECKIN
) {
  return {
    operation: operation,
    pegasus_ids: pegasusIds.map((id) => getPegasusID(id)),
    checkin_location: location,
  }
}

export function validationResponseToValidation(response = {}, pegasusIds = []) {
  return new Map(
    response.data.samples.map((sample = {}) => {
      const { pegasus_id = '', status = '' } = sample
      let { type = '' } = sample
      pegasusIds.map((pegasusId = '') => {
        // eslint-disable-next-line
        if (pegasusId.substring(2) == pegasus_id) {
          type =
            pegasusId.substring(0, 2).toString() === 'PP'
              ? 'PROPS'
              : pegasusId.substring(0, 2).toString() === 'PS'
                ? 'SCENIC'
                : 'SAMPLE'
        }
        return {}
      })
      const pegasusId = getSampleIDPrefix(pegasus_id, type)
      return [pegasusId, status]
    })
  )
}

export const addSamplesErrorRequests = (err = {}, sampleType) => {
  const { response: { data: { errors: responseDataErrors = [] } = {} } = {} } =
    err
  return responseDataErrors.map((error) =>
    addSampleErrorRequest(error, sampleType)
  )
}

export const addSamplesErrorRequestsNew = (
  responseDataErrors = [],
  sampleType
) => {
  return responseDataErrors.map((error) =>
    addSampleErrorRequestNew(error, sampleType)
  )
}

export function addSampleErrorRequestNew(error, sampleType) {
  const { pegasusId: errorPegasusId = '', projects = [], imageUrl = '' } = error
  return {
    pegasusId: getSampleIDPrefix(errorPegasusId, sampleType),
    projects: getProjectListData(projects),
    imageUrl: imageUrl,
  }
}

export function getProjectListData(projects = []) {
  return projects.map((project = {}) => projectData(project))
}

export function projectData(project = {}) {
  const {
    name: projectName = '',
    shoot_start_date = '',
    shoot_end_date = '',
    created_by = '',
  } = project
  return {
    projectName: projectName,
    shootStartDate: shoot_start_date,
    shootEndDate: shoot_end_date,
    createdBy: created_by,
  }
}

export function addSampleErrorRequest(error) {
  const {
    pegasusId: errorPegasusId = '',
    projectName = '',
    shootStartDate = '',
    shootEndDate = '',
    createdBy = '',
  } = error
  return {
    pegasusId: getSampleIDPrefix(errorPegasusId),
    projectName: projectName,
    shootStartDate: !isEmpty(shootStartDate) ? error.shootStartDate : '',
    shootEndDate: !isEmpty(shootEndDate) ? error.shootEndDate : '',
    createdBy: createdBy,
  }
}

export const addSamplesErrorRequest = (err) => {
  return err.response.data.errors.map((error) => error.resource)
}
export const addSamplesErrorRequestNew = (err) => {
  const {
    response: {
      data: { errors: errorSamples = [] },
    },
  } = err
  return [
    ...new Set(
      errorSamples.map((error) => {
        const {
          resource: { pegasusId = '' },
        } = error
        return pegasusId.toString()
      })
    ),
  ]
}

export const getErrorMessages = (err) => {
  return err.response.data.errors.map((error) => error.reason)
}

export const getUnknownSamples = (err) => {
  return err.response.data.map((error) => error.resource)
}

export function sampleIdsBuilder(data = [], type = SAMPLE) {
  return data.map((sample) => getSampleIDPrefix(sample, type) || sample)
}

export function sampleIdBuilder(sample) {
  return getSampleIDPrefix(sample) || sample
}

export const getErrorCodes = (err) => {
  return err.response.data.errors.map((error) => error.errorCode)
}

export function toVendorPageSortRequest(sortBy) {
  switch (sortBy) {
    case 'createDate':
      return 'createTs'
    case 'dueDate':
      return 'sampleDueDate'
    case 'destination':
      return 'shipToLoc'
    case 'testSortBy':
      return 'testSort'
    default:
      return sortBy
  }
}

export function autoOrderSamplesResponseToSamples(res = {}) {
  const {
    data: { auto_order_mapping = [], auto_order_mapping_v2 = [] },
  } = res
  return (auto_order_mapping_v2 || auto_order_mapping || []).map((obj) =>
    autoOrderSamplesResponseToSample(obj)
  )
}

export function autoOrderSamplesResponseToSample(data) {
  return {
    brandId: data.brands?.map((brand) => brand.id).toString(),
    brandName: data.brands?.map((brand) => brand.name).toString(),
    departmentId: data.department_id,
    shipToLocation: data.ship_to_location,
    size: (data.size || []).join(','),
    classId: data?.class_id,
    subClassId: data?.sub_class_id,
  }
}
