export const RenderEditFieldsAutoOrder = [
  {
    id: 'Location',
    label: 'Ship To Location',
    renderValue: 'Location',
    renderType: 'AutoComplete',
  },
  {
    id: 'Size',
    label: 'Size',
    renderValue: 'size',
    renderType: 'Text',
  },

  {
    id: 'Submit',
    label: 'Submit',
    renderValue: 'Submit',
    renderType: 'Button',
  },
]
export const aogTabLabels = [
  { label: 'Auto Order Grid', value: 'aog' },
  { label: 'Auto Order Grid SubClass', value: 'aogsubclass' },
]
export const RenderSelectionFields = [
  {
    id: 'Department',
    label: 'Department',
    renderValue: 'department',
    renderType: 'AutoComplete',
  },
  {
    id: 'Class',
    label: 'Class',
    renderValue: 'class',
    renderType: 'AutoComplete',
  },
  {
    id: 'SubClass',
    label: 'Sub Class',
    renderValue: 'subclass',
    renderType: 'AutoComplete',
  },
]
export const locationList = [
  {
    label: 'Splashlight Studio',
    value: 'Splashlight Studio',
  },
  {
    label: 'Schawk Chicago',
    value: 'Schawk Chicago',
  },
  {
    label: 'Schawk Shanghai',
    value: 'Schawk Shanghai',
  },
  {
    label: 'Winter Street Samples Props and Workshop',
    value: 'Winter Street Samples Props and Workshop',
  },
  {
    label: 'Periscope Studio Hong Kong',
    value: 'Periscope Studio Hong Kong',
  },
  {
    label: 'Periscope Studio Bloomington',
    value: 'Periscope Studio Bloomington',
  },
  {
    label: 'Industrial Color',
    value: 'Industrial Color',
  },
]
